/** @format */

import { CloseOutlined } from "@ant-design/icons";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Button, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { selectedShowFlowPanel } from "../../redux/reducers/CommonSlice/commonSliceSelectors";
import FlowPanel from "../FlowPanel";
import PairFlow from "../FlowPanel/PairFlow";
import { IFlowPanel } from "../FlowPanel/interfaces";
import SnackbarWrapper from "../Snackbar";
import TerminalList from "../TerminalsPage/TerminalsPage";
import PairForm from "./PairForm";
import PairStatus from "./PairStatus";
import useStyles from "./index.styles";

const PairPage: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const showFlowPanel = useAppSelector(selectedShowFlowPanel);
  const classes = useStyles(showFlowPanel as unknown as IFlowPanel);

  const [isViewTerminalOpen, setIsViewTerminalOpen] = useState(false);
  const [isPairPageOpen, setisPairPageOpen] = React.useState(false);
  const shouldNavigateToPayScreen = queryParams.get(
    "shouldNavigateToPayScreen"
  );

  useEffect(() => {
    if (shouldNavigateToPayScreen) {
      setisPairPageOpen(true);
    }
  }, [shouldNavigateToPayScreen]);
  const renderContent = () => (
    <>
      <div className="row w-100 d-flex justify-content-end">
        <Button
          onClick={() => {
            setIsViewTerminalOpen(true);
          }}
          type="primary"
          className="w-20"
          style={{ marginRight: "2rem" }}
        >
          View Paired Terminals
        </Button>
      </div>
      <Drawer
        title="Terminals"
        width={"968"}
        key={"right"}
        open={isViewTerminalOpen}
        onClose={() => {
          setIsViewTerminalOpen(false);
        }}
        mask={false}
      >
        <TerminalList />
      </Drawer>

      <div className={classes.root}>
        <div
          className={
            showFlowPanel
              ? `${classes.content} ${classes.contentShift}`
              : classes.content
          }
        >
          <Container>
            <Grid container>
              <Grid item sm={8} xs={12} className={classes.pairFormContainer}>
                <PairForm />
              </Grid>
              <Grid item sm={4} xs={12} className={classes.pairStatusContainer}>
                <PairStatus />
              </Grid>
            </Grid>
          </Container>
        </div>
        <FlowPanel>
          <PairFlow />
        </FlowPanel>
        <SnackbarWrapper />
      </div>
    </>
  );

  if (shouldNavigateToPayScreen) {
    return (
      <Modal
        backdrop="static"
        show={isPairPageOpen}
        size="xl"
        centered
        onHide={() => setisPairPageOpen(false)}
      >
        <div className="d-flex justify-content-between mt-3 ms-3 me-3">
          <h5>Pair Now</h5>
          <CloseOutlined
            onClick={() => {
              navigate("/isWebClosed/?isWebClosed=true");
            }}
            style={{
              color: "white",
              backgroundColor: "red",
              border: "2px solid red",
              borderRadius: "50%",
              padding: "10px",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.1)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          />
        </div>
        <div className="p-3">{renderContent()}</div>
      </Modal>
    );
  }

  return <div>{renderContent()}</div>;
};

export default PairPage;
