/** @format */

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SPI_PAIR_STATUS } from "../../../definitions/constants/commonConfigs";
import { useAppSelector } from "../../../redux/hooks";
import { selectPairFormSerialNumber } from "../../../redux/reducers/PairFormSlice/PairFormSelectors";
import { ITerminalProps } from "../../../redux/reducers/TerminalSlice/interfaces";
import { terminalInstance } from "../../../redux/reducers/TerminalSlice/terminalsSliceSelectors";
import PairPanelButtons from "./PairPanelButtons";
import PairPanelInformation from "./PairPanelInformation";
import useStyles from "./index.styles";

function PairStatus(): React.ReactElement {
  const classes = useStyles();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);

  const amountFromParam = queryParams.get("amount");
  const surchargeAmount = queryParams.get("surchargeAmount");
  const tipAmount = queryParams.get("tipAmount");
  const transactionType = queryParams.get("transactionType");
  const shouldNavigateToPayScreen = queryParams.get(
    "shouldNavigateToPayScreen"
  );
  const terminalsFromParam = queryParams.get("terminals");
  const pairFormSerialNumber = useAppSelector(selectPairFormSerialNumber);
  const terminal = useAppSelector(
    terminalInstance(pairFormSerialNumber)
  ) as ITerminalProps;

  const panelInformationList = [
    {
      title: "Merchant ID",
      content: terminal?.merchantId || "-",
    },
    {
      title: "Terminal ID",
      content: terminal?.terminalId || "-",
    },
    {
      title: "Battery",
      content: terminal?.batteryLevel || "-",
    },
  ];

  useEffect(() => {
    if (
      shouldNavigateToPayScreen &&
      terminal?.status === SPI_PAIR_STATUS.PairedConnected
    ) {
      navigate(
        `/?amount=${amountFromParam}&transactionType=${
          transactionType ?? ""
        }&surchargeAmount=${surchargeAmount ?? ""}&tipAmount=${
          tipAmount ?? ""
        }&terminals=${terminalsFromParam ?? ""}`
      );
    }
  }, [
    shouldNavigateToPayScreen,
    terminal,
    amountFromParam,
    transactionType,
    surchargeAmount,
    tipAmount,
    navigate,
    terminalsFromParam,
  ]);

  return (
    <Grid
      container
      direction="column"
      className={`${classes.flowBox} border-0`}
      id="pairStatus"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={`${classes.title} bg-theme p-2`}
      >
        <Typography variant="h6" component="h1" className="pairing-text">
          Pairing status
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        className={`${classes.statusPanel} border-0 `}
      >
        <Box display="flex" alignItems="center" className={classes.statusBox}>
          <Typography align="right">
            {
              PairPanelButtons(
                terminal?.status || SPI_PAIR_STATUS.Unpaired,
                null,
                terminal?.reconnecting || false
              ).statusIcon
            }
          </Typography>
          <Box display="flex" flexDirection="column" marginLeft={2}>
            <Typography variant="h5" className={classes.statusTitle}>
              {
                PairPanelButtons(
                  terminal?.status || SPI_PAIR_STATUS.Unpaired,
                  null,
                  terminal?.reconnecting || false
                ).statusTitle
              }
            </Typography>
            <Typography variant="inherit" className={classes.statusText}>
              {
                PairPanelButtons(
                  terminal?.status || SPI_PAIR_STATUS.Unpaired,
                  terminal?.pairingFlow?.message || SPI_PAIR_STATUS.Unpaired,
                  terminal?.reconnecting || false
                ).statusText
              }
            </Typography>
          </Box>
        </Box>

        {(terminal?.status === SPI_PAIR_STATUS.PairedConnected ||
          terminal?.status === SPI_PAIR_STATUS.Unpaired ||
          terminal?.reconnecting ||
          !terminal) &&
          panelInformationList.map(({ title, content }) => (
            <PairPanelInformation key={title} title={title} content={content} />
          ))}
        {terminal?.pairingFlow?.message ===
          "Confirm that the following Code is showing on the Terminal" && (
          <Box display="flex" flexDirection="column" marginLeft={2}>
            <Typography variant="h5" className={classes.pairingCode}>
              Pairing code: {terminal?.pairingFlow?.confirmationCode}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        className={`${classes.statusButtonBox} border-0`}
      >
        {
          PairPanelButtons(
            terminal?.status || SPI_PAIR_STATUS.Unpaired,
            null,
            terminal?.reconnecting || false
          ).button
        }
      </Grid>
    </Grid>
  );
}

export default PairStatus;
