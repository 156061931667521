/** @format */

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { Spi as SpiClient } from "@mx51/spi-client-js";
import React, { useEffect, useState } from "react";
import PairConfiugationLoading from "./PaymentConfigurationLoading";

import { Form, Input, Select } from "antd";
import { RuleObject } from "antd/lib/form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  SPI_PAIR_STATUS,
  TEXT_FORM_CONFIGURATION_AUTO_ADDRESS_VALUE,
  TEXT_FORM_CONFIGURATION_EFTPOS_ADDRESS_VALUE,
  TEXT_FORM_DEFAULT_VALUE,
} from "../../../../definitions/constants/commonConfigs";
import {
  defaultApikey,
  defaultPosName,
} from "../../../../definitions/constants/spiConfigs";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  pairForm,
  selectPairFormDeviceAddress,
  selectPairFormSerialNumber,
} from "../../../../redux/reducers/PairFormSlice/PairFormSelectors";
import { updatePairFormParams } from "../../../../redux/reducers/PairFormSlice/pairFormSlice";
import {
  terminalInstance,
  terminalList,
} from "../../../../redux/reducers/TerminalSlice/terminalsSliceSelectors";
import { serialNumberFormatter } from "../../../../utils/common/helpers";
import {
  handleAddressTypeSelectorOnBlur,
  handleAddressTypeSelectorOnChange,
  handleDeviceAddressFieldOnBlur,
  handleDeviceAddressFieldOnChange,
  handlePaymentProviderFieldOnBlur,
  handlePaymentProviderFieldOnChange,
  handlePaymentProviderSelectorOnChange,
  handlePosIdFieldOnBlur,
  handlePosIdFieldOnChange,
  handleSerialNumberFieldOnBlur,
  handleSerialNumberFieldOnChange,
  handleTestModeCheckboxOnChange,
  isHttps,
} from "../../../../utils/common/pair/pairFormHelpers";
import {
  eftposAddressValidator,
  fieldRequiredValidator,
  isSerialNumberValid,
  paymentProviderValidator,
  postIdValidator,
  serialNumberValidatorOnBlur,
  serialNumberValidatorOnChange,
} from "../../../../utils/validators/validators";
import useStyles from "../index.styles";
import { IFormEventCheckbox, IFormEventValue } from "../interfaces";

export default function PairConfiguration(): React.ReactElement {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const acuirerCodeFromParam = queryParams.get("acquirerCode");
  const serialNumberFromParam = queryParams.get("serialNumber");
  const posIdFromParam = queryParams.get("posId");
  // read redux store states
  const { acquirerCode, addressType, posId, serialNumber, testMode } =
    useAppSelector(pairForm);
  const pairFormDeviceAddress = useAppSelector(selectPairFormDeviceAddress);
  const pairFormSerialNumber = useAppSelector(selectPairFormSerialNumber);
  const terminal = useAppSelector(terminalInstance(pairFormSerialNumber));
  const terminals = useAppSelector(terminalList);
  const [tenantList, setTenantList] = useState<TenantList>([]);

  const { getPaymentProviderLoading } = useSelector(
    (state: any) => state.posDeviceSettingsReducer
  );

  useEffect(() => {
    let pos: any;
    if (posId) {
      pos = posId;
    } else {
      pos = defaultPosName;
    }
    SpiClient.GetAvailableTenants("", defaultApikey, "AU").then(
      (response: TenantListResponse) => {
        setTenantList(response.Data);
      }
    );
  }, []);

  useEffect(() => {
    if (acuirerCodeFromParam) {
      dispatch(
        updatePairFormParams({
          key: "acquirerCode",
          value: {
            value: acuirerCodeFromParam,
            option: acuirerCodeFromParam,
            isValid: fieldRequiredValidator(acuirerCodeFromParam),
          },
        })
      );
    }

    if (serialNumberFromParam) {
      dispatch(
        updatePairFormParams({
          key: "serialNumber",
          value: {
            value: serialNumberFormatter(serialNumberFromParam),
            isValid:
              serialNumberValidatorOnChange(serialNumberFromParam) === "",
          },
        })
      );
    }
    if (posIdFromParam) {
      dispatch(
        updatePairFormParams({
          key: "posId",
          value: {
            value: posIdFromParam,
            isValid: true,
          },
        })
      );
    }
  }, [
    acuirerCodeFromParam,
    serialNumberFromParam,
    posIdFromParam,
    search,
    dispatch,
  ]);

  return (
    <>
      <Grid item className={classes.title}>
        <Typography
          variant="h6"
          component="h1"
          className={classes.configurationTitle}
        >
          Pairing configuration Details
        </Typography>
      </Grid>
      {getPaymentProviderLoading ? (
        <PairConfiugationLoading />
      ) : (
        <Grid container direction="column">
          <Grid container direction="row" className={classes.fieldSpace}>
            <Grid
              item
              sm={6}
              xs={12}
              className={`${classes.columnSpace} border-0`}
            >
              <Form.Item
                label="Payment Provider"
                name={"paymentProvider"}
                rules={[
                  {
                    required: true,
                    message: "Please Choose Payment Provider",
                  },
                ]}
              >
                {" "}
                <Select
                  placeholder="Payment Provider"
                  disabled={
                    terminal?.status === SPI_PAIR_STATUS.PairedConnecting
                  }
                  onChange={(event: string) =>
                    handlePaymentProviderSelectorOnChange(
                      dispatch,
                      event,
                      fieldRequiredValidator,
                      updatePairFormParams
                    )
                  }
                  // value={acquirerCode.option}
                >
                  {" "}
                  {tenantList.map((tenant: Tenant) => (
                    <Select.Option key={tenant.code} value={tenant.code}>
                      {tenant.name}
                    </Select.Option>
                  ))}
                  <Select.Option value={TEXT_FORM_DEFAULT_VALUE}>
                    {" "}
                    Other
                  </Select.Option>
                </Select>
              </Form.Item>
            </Grid>

            <Grid item sm={6} xs={12}>
              <Form.Item label={TEXT_FORM_DEFAULT_VALUE}>
                <Input
                  placeholder={TEXT_FORM_DEFAULT_VALUE}
                  onBlur={(event: IFormEventValue) =>
                    handlePaymentProviderFieldOnBlur(
                      dispatch,
                      event,
                      paymentProviderValidator,
                      updatePairFormParams
                    )
                  }
                  onChange={(event: IFormEventValue) =>
                    handlePaymentProviderFieldOnChange(
                      dispatch,
                      event,
                      paymentProviderValidator,
                      updatePairFormParams
                    )
                  }
                  disabled={
                    acquirerCode.option !== TEXT_FORM_DEFAULT_VALUE ||
                    terminal?.status === SPI_PAIR_STATUS.PairedConnecting
                  }
                  value={acquirerCode.value}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.fieldSpace}>
            <Grid item sm={6} xs={12} className={classes.columnSpace}>
              <Form.Item label="Configuration Option">
                <Select
                  disabled={
                    terminal?.status === SPI_PAIR_STATUS.PairedConnecting
                  }
                  onBlur={() =>
                    handleAddressTypeSelectorOnBlur(
                      dispatch,
                      testMode,
                      updatePairFormParams
                    )
                  }
                  onChange={(event: string) =>
                    handleAddressTypeSelectorOnChange(
                      dispatch,
                      event,
                      updatePairFormParams
                    )
                  }
                  placeholder="Configuration Option"
                  // value={addressType}
                >
                  <Select.Option
                    value={TEXT_FORM_CONFIGURATION_AUTO_ADDRESS_VALUE}
                    disabled={!isHttps()}
                  >
                    Auto Address
                  </Select.Option>
                  <Select.Option
                    value={TEXT_FORM_CONFIGURATION_EFTPOS_ADDRESS_VALUE}
                    disabled={isHttps()}
                  >
                    EFTPOS address
                  </Select.Option>
                </Select>
              </Form.Item>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Form.Item label="EFTPOS address">
                <Input
                  disabled={
                    addressType ===
                      TEXT_FORM_CONFIGURATION_AUTO_ADDRESS_VALUE ||
                    terminal?.status === SPI_PAIR_STATUS.PairedConnecting
                  }
                  onBlur={(event: IFormEventValue) =>
                    handleDeviceAddressFieldOnBlur(
                      addressType,
                      dispatch,
                      event,
                      eftposAddressValidator,
                      updatePairFormParams
                    )
                  }
                  onChange={(event: IFormEventValue) =>
                    handleDeviceAddressFieldOnChange(
                      dispatch,
                      event,
                      updatePairFormParams
                    )
                  }
                  value={pairFormDeviceAddress}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid item className={classes.fieldSpace}>
            <Form.Item
              initialValue={serialNumber.value}
              name={"serialNumber"}
              label="Serial Number"
              rules={[
                {
                  required: true,
                  message: "Please enter Serial Number",
                },
                {
                  validator: (
                    rule: RuleObject,
                    value: any,
                    callback: (error?: string) => void
                  ) => {
                    if (!isSerialNumberValid(value) && value) {
                      callback(
                        "Invalid serial number. Please avoid using spaces."
                      );
                    } else {
                      callback(); // No error, so call the callback with no arguments
                    }
                  },
                },
              ]}
            >
              <Input
                placeholder="Serial Number"
                disabled={terminal?.status === SPI_PAIR_STATUS.PairedConnecting}
                onBlur={(event: IFormEventValue) =>
                  handleSerialNumberFieldOnBlur(
                    dispatch,
                    event,
                    serialNumberValidatorOnBlur,
                    updatePairFormParams
                  )
                }
                value={serialNumber.value}
                onChange={(event: IFormEventValue) =>
                  handleSerialNumberFieldOnChange(
                    dispatch,
                    event,
                    serialNumberValidatorOnChange,
                    updatePairFormParams
                  )
                }
              />
            </Form.Item>
          </Grid>
          <Grid item className={classes.fieldSpace}>
            <Form.Item
              name={"posID"}
              label="POS ID"
              rules={[{ required: true, message: "Please enter POS ID" }]}
            >
              <Input
                placeholder="POS ID"
                onBlur={(event: IFormEventValue) =>
                  handlePosIdFieldOnBlur(
                    dispatch,
                    event,
                    postIdValidator,
                    terminals,
                    updatePairFormParams
                  )
                }
                onChange={(event: IFormEventValue) =>
                  handlePosIdFieldOnChange(
                    dispatch,
                    event,
                    postIdValidator,
                    terminals,
                    updatePairFormParams
                  )
                }
                value={posId.value}
                disabled={terminal?.status === SPI_PAIR_STATUS.PairedConnecting}
              />
            </Form.Item>
          </Grid>
          <Grid item className={classes.fieldSpace}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    testMode && acquirerCode.value.toLowerCase() !== "gko"
                  }
                  color="primary"
                  disabled={
                    terminal?.status === SPI_PAIR_STATUS.PairedConnecting ||
                    acquirerCode.value.toLowerCase() === "gko"
                  }
                  data-test-id="testModeCheckbox"
                  name="testMode"
                  onChange={(event: IFormEventCheckbox) =>
                    handleTestModeCheckboxOnChange(
                      dispatch,
                      event,
                      updatePairFormParams
                    )
                  }
                />
              }
              label="Test mode"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
