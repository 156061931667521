/** @format */

import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PayNow from "../../mx51/components/PayNow";
import UnknownTransaction from "./UnknownTransaction";

const PayScreenForTab = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(search);
  const amountFromParam = queryParams.get("amount");
  const transactionType = queryParams.get("transactionType");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const [
    isUnknowEftposTransactionModalOpen,
    setIsUnknownEftpostTransactionModalOpen,
  ] = useState(false);

  const navigate = useNavigate();
  // const terminals = useSelector(pairedConnectedTerminalList);
  // const selectedTerminal = useSelector(selectedTerminalIdSelector);

  // const currentTerminal = useSelector(terminalInstance(selectedTerminal));

  // const terminalList = Object.values(termianls);
  // const isTerminalPaired = useSelector(isPaired);

  useEffect(() => {
    if (amountFromParam && transactionType) {
      setIsModalOpen(true);
    }
  }, [amountFromParam, transactionType]);

  useEffect(() => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 4000);
  }, []);

  useEffect(() => {
    dispatch({
      type: "GET_RELEASE_VERSION_REQUEST",
    });
  }, [dispatch]);

  return (
    <>
      <UnknownTransaction
        isUnknowEftposTransactionModalOpen={isUnknowEftposTransactionModalOpen}
        setIsUnknownEftpostTransactionModalOpen={
          setIsUnknownEftpostTransactionModalOpen
        }
      />

      <Modal
        show={isModalOpen}
        centered
        onHide={() => {
          setIsModalOpen(false);
          navigate("/isWebClosed/?isWebClosed=true");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "19px" }}>
            Choose Terminal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="position-relative">
            <PayNow />
            {showSpinner && (
              <div
                className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                style={{
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <Spinner animation="border" />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PayScreenForTab;
