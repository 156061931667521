/** @format */

import {
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Button, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PATH_CASH_OUT,
  TEXT_CASHOUT,
  TEXT_PURCHASE,
  TEXT_REFUND,
} from "../../definitions/constants/routerConfigs";
import { TxFlowState } from "../../definitions/constants/terminalConfigs";
import {
  orderCashoutAmountSelector,
  productSubTotalSelector,
} from "../../redux/reducers/ProductSlice/productSelector";
import {
  addKeypadAmount,
  clearAllProducts,
} from "../../redux/reducers/ProductSlice/productSlice";
import { updateSelectedTerminal } from "../../redux/reducers/SelectedTerminalSlice/selectedTerminalSlice";
import selectedTerminalIdSelector from "../../redux/reducers/SelectedTerminalSlice/selectedTerminalSliceSelector";
import { ITerminalProps } from "../../redux/reducers/TerminalSlice/interfaces";
import { updateTxFlow } from "../../redux/reducers/TerminalSlice/terminalsSlice";
import {
  pairedConnectedTerminalList,
  terminalInstance,
  terminalTxFlowFinishedTracker,
  terminalTxFlowReceipt,
} from "../../redux/reducers/TerminalSlice/terminalsSliceSelectors";
import {
  cancelTransaction,
  initiateCashoutOnlyTx,
  initiatePurchase,
  initiateRefundTx,
  setTerminalToIdle,
} from "../../utils/common/purchase/purchaseHelper";
import KeyPad from "../KeyPad";
import TransactionProgressModal from "../TransactionProgressModal";
import UnknownTransactionModal from "../UnknownTransactionModal";

import { extractBase64ToArray, UrlTerminal } from "../../utils/common/helpers";
import useStyles from "./index.styles";
import { ITitleStrategy } from "./interfaces";

function convertAmountToCents(amount) {
  const amountInCents = parseFloat(amount) * 100;
  return amountInCents;
}

function OrderConfirmation({
  title,
  pathname,
  currentAmount,
}): React.ReactElement {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);

  const amountFromParam = queryParams.get("amount");
  const surchargeAmount = queryParams.get("surchargeAmount");
  const tipAmount = queryParams.get("tipAmount");
  const transactionType = queryParams.get("transactionType");
  const terminalsFromParam = queryParams.get("terminals");

  const classes = useStyles();
  // const surchargeAmount: number = useSelector(orderSurchargeAmountSelector);
  // const tipAmount: number = useSelector(orderTipAmountSelector);
  const cashoutAmount: number = useSelector(orderCashoutAmountSelector);
  const terminals = useSelector(pairedConnectedTerminalList);
  const [subtotalAmount, setSubtotalAmount] = useState<number>(
    useSelector(productSubTotalSelector)
  );
  const selectedTerminal = useSelector(selectedTerminalIdSelector);
  const currentTerminal = useSelector(
    terminalInstance(selectedTerminal)
  ) as ITerminalProps;
  const isFinished =
    useSelector(terminalTxFlowFinishedTracker(selectedTerminal)) ?? false;
  const receipt = useSelector(terminalTxFlowReceipt(selectedTerminal));

  const successStatus = currentTerminal?.txFlow?.success;

  const isUnknownState = isFinished && successStatus === TxFlowState.Unknown;

  const clearAllProductsAction = () => {
    dispatch(clearAllProducts());
  };

  const [displayKeypad, setDisplayKeypad] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(currentAmount);
  const [showTransactionProgressModal, setShowTransactionProgressModal] =
    useState<boolean>(false);
  const [toShowUnknownTransaction, setToShowUnknownTransaction] =
    useState<boolean>(false);

  const [showUnknownTransactionModal, setShowUnknownTransactionModal] =
    useState<boolean>(true);

  const [terminalsFromUrl, setTerminalsFromUrl] = useState<UrlTerminal[]>([]);
  const [shouldShowTerminalsList, setShouldShowTerminalsList] =
    useState<boolean>(true);
  const filteredUnpairedTerminals = terminalsFromUrl.filter(
    (item) =>
      item.serial_number &&
      !terminals.some(
        (terminal) => terminal.serialNumber === item.serial_number
      )
  );

  function selectTerminal(terminalId: string) {
    dispatch(updateSelectedTerminal(terminalId));
  }

  function isDisabled() {
    return !selectedTerminal || totalAmount <= 0;
  }

  function updateUnknownTerminalState(success: string) {
    setTerminalToIdle(selectedTerminal);
    setShowUnknownTransactionModal(false);
    if (currentTerminal?.txFlow != null) {
      dispatch(
        updateTxFlow({
          id: selectedTerminal,
          txFlow: { ...currentTerminal?.txFlow, finished: true, success },
        })
      );
    }
  }

  const titleStrategy: ITitleStrategy = {
    Pay: `Override ${TEXT_PURCHASE}`,
    [TEXT_CASHOUT]: TEXT_CASHOUT,
    [TEXT_REFUND]: TEXT_REFUND,
  };

  function getTitleForKeypad(): string {
    return title in titleStrategy
      ? (titleStrategy as unknown as Record<string, keyof ITitleStrategy>)[
          title
        ]
      : title;
  }
  const { state } = useLocation();

  // My Code

  useEffect(() => {
    if (amountFromParam) {
      setTotalAmount(convertAmountToCents(amountFromParam));
      setSubtotalAmount(convertAmountToCents(amountFromParam));
    }
  }, [amountFromParam]);

  useEffect(() => {
    if (terminalsFromParam) {
      const base64terminals = terminalsFromParam;
      const terminals = extractBase64ToArray(base64terminals);
      setTerminalsFromUrl(terminals);
    }
  }, [terminalsFromParam]);

  const posRefId = `purchase-${new Date().toISOString()}`;
  const posRefIdRefund = `refund-${new Date().toISOString()}`;

  useEffect(() => {
    if (terminals.length > 0 && terminalsFromUrl.length > 0) {
      const defaultTerminal = terminals.find((terminal) =>
        terminalsFromUrl.some(
          (urlTerminal) =>
            urlTerminal.serial_number === terminal.serialNumber &&
            urlTerminal.is_default
        )
      );
      if (defaultTerminal) {
        selectTerminal(defaultTerminal.id);
      }
    }
  }, [terminals, terminalsFromUrl, selectTerminal, dispatch]);

  return (
    <>
      <Drawer
        anchor="right"
        open={displayKeypad}
        classes={{
          paper: classes.keypadDrawerPaper,
        }}
      >
        <KeyPad
          open={displayKeypad}
          title={getTitleForKeypad()}
          subtitle={`Enter ${getTitleForKeypad().toLowerCase()} amount`}
          defaultAmount={totalAmount}
          onClose={() => {
            setDisplayKeypad(false);
          }}
          onAmountChange={(amount) => {
            setTotalAmount(amount);
            setSubtotalAmount(amount);
            setDisplayKeypad(false);
            clearAllProductsAction();
            dispatch(addKeypadAmount(amount));
          }}
        />
      </Drawer>
      {filteredUnpairedTerminals.length > 0 && shouldShowTerminalsList && (
        <div>
          {/* <h6 className="mb-3 ms-2">Unpaired Terminals</h6> */}
          {filteredUnpairedTerminals.map((terminal) => (
            <Box
              key={terminal.serial_number}
              border={3}
              borderRadius={8}
              p={1}
              m={1}
              style={{ border: "1px solid #eee" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <Typography style={{ lineHeight: 2 }}>
                    <span className="fw-bold">Serial Number: </span>{" "}
                    {terminal.serial_number}{" "}
                    <Tag color={"error"} className="ms-1">
                      Unpaired
                    </Tag>
                  </Typography>
                  <Typography.Text type="secondary" style={{ lineHeight: 2 }}>
                    <span className="fw-bold"> POS ID:</span> {terminal.pos_id}
                  </Typography.Text>
                </div>
                <Button
                  color="primary"
                  type="default"
                  style={{
                    border: "1px solid #1a9aa5",
                    color: "#1a9aa5",
                    borderRadius: "8px",
                  }}
                  onClick={() =>
                    navigate(
                      `/pair-terminal-mx51?amount=${amountFromParam}&transactionType=${transactionType}&surchargeAmount=${
                        surchargeAmount ?? ""
                      }&tipAmount=${tipAmount ?? ""}&serialNumber=${
                        terminal.serial_number
                      }&posId=${terminal.pos_id}&terminals=${
                        terminalsFromParam ?? ""
                      }&shouldNavigateToPayScreen=true`
                    )
                  }
                >
                  Pair
                </Button>
              </div>
            </Box>
          ))}
        </div>
      )}
      {/* <hr /> */}

      {/* {terminals.length > 0 && <h6 className="ms-2 mb-0">Paired Terminals</h6>} */}
      <Grid container>
        <Grid item xs={12}>
          <Box
            className={`${classes.root} choose-terminal-and-pay-button p-2`}
            display="flex"
            flexDirection="column"
          >
            {shouldShowTerminalsList && (
              <RadioGroup
                className={`${classes.radioGroup} terminal-container`}
                aria-label="terminalList"
                name="terminalList"
              >
                <Box
                  className={terminals.length > 0 ? "mb-2" : ""}
                  style={
                    terminals.length > 0
                      ? {
                          border: "1px solid #eee",
                          padding: "0px",
                          borderRadius: "8px",
                        }
                      : {}
                  }
                >
                  <List>
                    {terminals.map((terminal) => (
                      <ListItem
                        key={terminal.id}
                        dense
                        disableGutters
                        onClick={() => selectTerminal(terminal.id)}
                      >
                        <ListItemIcon>
                          <Radio
                            className={classes.radioBtn}
                            checked={terminal.id === selectedTerminal}
                            value={terminal.id}
                            name="terminal"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={terminal.posId}
                          secondary={`${terminal.deviceAddress} S/N ${terminal.serialNumber}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </RadioGroup>
            )}

            {/* {pathname === PATH_PAY_NOW && (
              <>
                <Typography className={classes.label}>
                  Select payment method
                </Typography>
                <Divider />
              </>
            )} */}
            {showUnknownTransactionModal && isUnknownState && (
              <UnknownTransactionModal
                onSuccessTransaction={() => {
                  updateUnknownTerminalState("Success");
                  setToShowUnknownTransaction(true);
                }}
                onFailedTransaction={() => {
                  updateUnknownTerminalState("Failed");
                  setToShowUnknownTransaction(true);
                }}
              />
            )}
            {showTransactionProgressModal &&
              (!isUnknownState || toShowUnknownTransaction) && (
                <TransactionProgressModal
                  terminalId={selectedTerminal}
                  transactionType={currentTerminal?.txFlow?.type ?? ""}
                  transactionDesc={receipt?.hostResponseText ?? ""}
                  isFinished={isFinished}
                  isSuccess={successStatus === "Success"}
                  onCancelTransaction={() => {
                    cancelTransaction(selectedTerminal);
                  }}
                  onRetryTransaction={() => {
                    setShouldShowTerminalsList(true);
                    setShowTransactionProgressModal(false);
                    // setmx51PayPageOpen(false);
                  }}
                  onDone={() => {
                    setShowTransactionProgressModal(false);
                  }}
                  posRefId={posRefId}
                />
              )}
            {transactionType === "PAYMENT_TRANSACTION" && (
              <Button
                type="primary"
                size="large"
                disabled={isDisabled()}
                onClick={async () => {
                  setShouldShowTerminalsList(false);
                  setShowTransactionProgressModal(true);
                  navigate(
                    `?amount=${amountFromParam}&isPayClicked=true&transactionType=${transactionType}&surchargeAmount=${
                      surchargeAmount ?? ""
                    }&tipAmount=${tipAmount ?? ""}`
                  );
                  localStorage.setItem("posRefId", posRefId);
                  localStorage.setItem(
                    "transactionType",
                    currentTerminal?.txFlow?.type ?? ""
                  );
                  // setmx51PayPageOpen(false);
                  initiatePurchase(
                    selectedTerminal,
                    subtotalAmount,
                    convertAmountToCents(tipAmount),
                    cashoutAmount,
                    convertAmountToCents(surchargeAmount),
                    false,
                    posRefId
                  );
                }}
              >
                INITIATE PAYMENT
              </Button>
            )}
            {pathname === PATH_CASH_OUT && (
              <Button
                color="primary"
                size="large"
                className="pay-button-mx51"
                disabled={isDisabled()}
                onClick={() => {
                  setShowTransactionProgressModal(true);

                  initiateCashoutOnlyTx(
                    selectedTerminal,
                    totalAmount,
                    convertAmountToCents(surchargeAmount)
                  );
                }}
              >
                Cashout
              </Button>
            )}
            {transactionType === "REFUND_TRANSACTION" && (
              <Button
                type="primary"
                size="large"
                disabled={isDisabled()}
                onClick={() => {
                  navigate(
                    `?amount=${amountFromParam}&isRefundClicked=true&transactionType=${transactionType}`
                  );
                  localStorage.setItem("posRefId", posRefIdRefund);
                  localStorage.setItem(
                    "transactionType",
                    currentTerminal?.txFlow?.type ?? ""
                  );
                  setShowTransactionProgressModal(true);
                  initiateRefundTx(
                    selectedTerminal,
                    totalAmount,
                    posRefIdRefund
                  );
                }}
              >
                REFUND
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default OrderConfirmation;
